import React from 'react';
import PropTypes from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useAuth } from "react-use-auth"

import Layout from '../Layout';
import SEO from '../SEO';
import PostNav from './PostNav';
import EditGithub from './EditGithub';

export default function Docs({ mdx, pageContext }) {
  const { prev, next, repositoryEditUrl, repositoryProvider } = pageContext;
  const { title, description, image, disableTableOfContents, authentication } = mdx.frontmatter;
  const { headings, body } = mdx;
  const { slug } = mdx.fields;
  const { gitLogLatestDate } = mdx.parent.fields;

  const { isAuthenticated, login } = useAuth();

  if (authentication === true ) {
    if (isAuthenticated()) {
        return (
        <>
          <SEO title={title} description={description} slug={slug} image={image} />
          <Layout
            disableTableOfContents={disableTableOfContents}
            title={title}
            headings={headings}
            gitLogLatestDate={gitLogLatestDate}
          >
            <MDXRenderer>{body}</MDXRenderer>
            <EditGithub
              repositoryEditUrl={repositoryEditUrl}
              repositoryProvider={repositoryProvider}
            />
            <PostNav prev={prev} next={next} />
          </Layout>
        </>
        )
    } else {
      login()
      return <p>Redirecting to login...</p>
  }
  } else {
      return (
      <>
        <SEO title={title} description={description} slug={slug} image={image} />
        <Layout
          disableTableOfContents={disableTableOfContents}
          title={title}
          headings={headings}
        >
          <MDXRenderer>{body}</MDXRenderer>
          <EditGithub
            repositoryEditUrl={repositoryEditUrl}
            repositoryProvider={repositoryProvider}
          />
          <PostNav prev={prev} next={next} />
        </Layout>
      </>
      )
  }
}

Docs.propTypes = {
  mdx: PropTypes.shape({
    body: PropTypes.string,
    headings: PropTypes.array,
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      disableTableOfContents: PropTypes.bool,
      authentication: PropTypes.bool,
      route: PropTypes.string,
    }),
    fields: PropTypes.shape({
      slug: PropTypes.string,
    }),
    parent: PropTypes.shape({
      fields: PropTypes.shape({
        gitLogLatestDate: PropTypes.string,
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    prev: PropTypes.shape({}),
    next: PropTypes.shape({}),
    repositoryEditUrl: PropTypes.string,
    repositoryProvider: PropTypes.string,
  }).isRequired,
};
